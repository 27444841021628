<script>
import InputField from '@/components/general/InputField'
import SectionBlock from '@/components/general/SectionBlock'
import TextArea from '@/components/general/TextArea'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import { required, email, maxLength } from 'vuelidate/lib/validators'
export default {
  components: { SectionBlock, InputField, TextArea, Breadcrumbs },
  name: 'EditBookmark',
  data () {
    return {
      isEdit: this.$route.name.indexOf('new') === -1,
      attemptingSave: false,
      isReady: this.$route.name.indexOf('new') > -1,
      id: '',
      formData: {
        name: '',
        socialName: '',
        cnpj: '',
        ein: '',
        mainContact: '',
        phone: '',
        email: '',
        observations: ''
      },
      dependents: []
    }
  },
  validations: {
    formData: {
      name: {
        required,
        maxLength: maxLength(255)
      },
      email: {
        email
      },
      socialName: {
        maxLength: maxLength(255)
      },
      cnpj: {
        maxLength: maxLength(20)
      },
      ein: {
        maxLength: maxLength(20)
      },
      mainContact: {
        maxLength: maxLength(150)
      },
      phone: {
        maxLength: maxLength(20)
      }
    }
  },
  created () {
    if (this.isEdit) this.getDependentById()
    this.getDependents()
  },
  methods: {
    getDependentById () {
      this.$store.dispatch('attemptGetDependent', this.$route.params.id)
        .then(({ data }) => {
          this.formData.name = data.businessName
          this.formData.socialName = data.corporateName
          this.formData.cnpj = data.identification
          this.formData.ein = data.identification
          this.formData.mainContact = data.contact
          this.formData.phone = data.phone
          this.formData.email = data.email
          this.formData.observations = data.notes
          this.isReady = true
        })
    },
    getDependents () {
      this.$store.dispatch('attemptGetDependents')
        .then(({ data }) => {
          this.dependents = data.map((dependent) => (
            {
              id: dependent._id,
              name: dependent.businessName
            }
          ))
          this.filteredItems = data.sort((a, b) => a.businessName.localeCompare(b.businessName))
          this.isReady = true
        })
    },
    updateDependent () {
      const existDependent = this.dependents.filter(f => f.name === this.formData.name && f.id !== this.$route.params.id)
      if (!existDependent.length) {
        this.attemptingSave = true
        const data = {
          _id: this.$route.params.id,
          businessName: this.formData.name,
          corporateName: this.formData.socialName,
          identification: this.getCompanyCountry === 'BRA' ? this.formData.cnpj : this.formData.ein,
          contact: this.formData.mainContact,
          phone: this.formData.phone,
          notes: this.formData.observations,
          email: this.formData.email
        }
        this.$store.dispatch('attemptUpdateDependent', data)
          .then(() => {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'success',
              title: this.$t('settings.admincenter.labels:submit.success')
            })
            this.$router.push({ name: 'business.admin.center.bookmarks' })
          })
          .catch(err => {
            if (err) {
              if (err.response.data.error.includes('dependentExists')) {
                this.$store.dispatch('attemptSetFeedbackMsg', {
                  type: 'error',
                  title: this.$t('admin.center.bookmarks:add.bookmarker.error')
                })
              } else {
                this.$store.dispatch('attemptSetFeedbackMsg', {
                  type: 'error',
                  title: this.$t('settings.admincenter.labels:submit.error')
                })
              }
            }
          })
          .finally(() => {
            this.attemptingSave = false
          })
      } else {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('admin.center.bookmarks:add.bookmarker.error')
        })
      }
    },
    saveDependent () {
      this.attemptingSave = true
      const data = {
        businessName: this.formData.name,
        contact: this.formData.mainContact,
        corporateName: this.formData.socialName,
        email: this.formData.email,
        identification: this.getCompanyCountry === 'BRA' ? this.formData.cnpj : this.formData.ein,
        notes: this.formData.observations,
        phone: this.formData.phone
      }
      this.$store.dispatch('attemptSaveDependent', data)
        .then((response) => {
          if (response.data.msg === undefined) {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'success',
              title: this.$t('settings.admincenter.labels:submit.success')
            })
            this.attemptingSave = false
            this.$router.push({ name: 'business.admin.center.bookmarks' })
          } else if (response.data.msg === 'duplicate') {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('admin.center.bookmarks:add.bookmarker.error')
            })
            this.attemptingSave = false
          }
        })
        .catch(err => {
          if (err) {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('settings.admincenter.labels:submit.error')
            })
            this.attemptingSave = false
          }
        })
    },
    attemptSubmit () {
      this.isEdit ? this.updateDependent() : this.saveDependent()
    }
  },
  computed: {
    breadcrumbsPaths () {
      const path = [
        {
          text: 'admin.center:menu.2:title',
          href: this.$router.resolve({ name: 'business.admin.center.bookmarks' }).href
        },
        {
          text: this.isEdit ? this.formData.name : 'admin.center.bookmarks:add.bookmarker.btn',
          href: this.$route.path
        }
      ]
      return path
    },
    getCompanyCountry () {
      return this.selectedWorkspace.region
    }
  }
}
</script>
<template>
  <section v-if="isReady" class="edit-bookmark--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths" />
      <h2>{{ isEdit ? formData.name : $t('admin.center.bookmarks:add.bookmarker.btn') }}</h2>
    </div>
    <div class="edit-bookmark--body">
      <section-block title="admin.center.bookmark:form.bookmark.title">
        <p class="bold">{{ $t('admin.center.bookmark:form.name') }}*</p>
        <input-field v-model.trim="formData.name" dense outlined persistent-placeholder
          :validation="$v.formData.name"></input-field>
      </section-block>
      <section-block title="admin.center.bookmark:form.details.title">
        <input-field v-model.trim="formData.socialName" dense outlined persistent-placeholder
          :label="$t('admin.center.bookmark:form.social.name')" :validation="$v.formData.socialName"></input-field>
        <div class="flex-horizontal small-info-block-2">
          <input-field v-if="getCompanyCountry === 'BRA'" v-model.trim="formData.cnpj" dense outlined persistent-placeholder
            :label="$t('admin.center.bookmark:form.CNPJ')" mask="##.###.###/####-##" :validation="$v.formData.cnpj"></input-field>
          <input-field  v-else v-model.trim="formData.ein" dense outlined persistent-placeholder
            :label="$t('admin.center.company:form.indentify.ein')" mask="##.#######" :validation="$v.formData.ein"></input-field>
          <input-field v-model.trim="formData.mainContact" dense outlined persistent-placeholder
            :label="$t('admin.center.bookmark:form.main.contact')" :validation="$v.formData.mainContact"></input-field>
        </div>
        <div class="flex-horizontal small-info-block-2">
          <input-field v-model.trim="formData.phone" dense outlined persistent-placeholder
            :label="$t('admin.center.bookmark:form.phone')" :validation="$v.formData.phone"></input-field>
            <input-field v-model.trim="formData.email" dense outlined persistent-placeholder
            :label="$t('admin.center.bookmark:form.email')" :validation="$v.formData.email"></input-field>
        </div>
      </section-block>
      <section-block title="admin.center.bookmark:form.observation.title">
        <text-area v-model.trim="formData.observations" outlined hide-validation></text-area>
      </section-block>
      <div class="edit-bookmark--actions">
        <v-btn class="btn transform-unset" :disabled="$v.$invalid || attemptingSave" :color="getPrimaryColor"
          :dark="!($v.$invalid || attemptingSave)" @click="attemptSubmit()">
          <v-icon class="mr-2" size="20" color="#fff">mdi-content-save-outline</v-icon>{{ $t(attemptingSave ?
            'global:wait' : 'global:save.changes') }}
        </v-btn>
      </div>
    </div>
</section>
</template>
<style lang="scss">
.edit-bookmark--container {

  .flex-container {
    display: flex;
    justify-content: space-between;

    .flex-container-input {
      width: 48%;

    }
  }

  .edit-bookmark--actions {
    text-align: right;
    margin-top: 40px;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 448px) {
  .edit-bookmark--container {

    .flex-container {
      flex-direction: column;

      .flex-container-input {
        width: 100%;

      }
    }
  }
}</style>
