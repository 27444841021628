<script>
export default {
  name: 'SectionBlock',
  props: {
    title: {
      type: String
    },
    dynamicTitle: {
      type: String
    }
  }
}
</script>
<template>
  <div class="section-block--container">
    <div class="section-block--header">
      <h3>{{ $t(title) }} <span v-if="dynamicTitle">{{ "(" + dynamicTitle.toUpperCase() + ")" }}</span></h3>
    </div>
    <div class="section-block--view">
      <slot></slot>
    </div>
    <div class="section-block-bottom" v-if="!!$slots.bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>
<style lang="scss">
.section-block--container {
  display: block;
  width: 100%;
  margin-top: 40px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  .section-block--header {
    background: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px 24px;
    text-align: left;
    h3 {
      font-family: $lato;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #454545;
    }
  }
  .section-block--view {
    padding: 24px;
    background: #fff;
    text-align: left;
  }
  .section-block-bottom {
    padding: 16px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    text-align: right;
  }
}
</style>
